import LayoutComponent from '../components/layout';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBanner, getFeaturedProduct, getProductMain, getProductParent, getSpecialProduct } from './api/hello';
import { IBanner } from '../@types/interface';
import environment from '../utils/environment';
import Link from 'next/link';
import ProductCategorySke from '../components/home/loading/product_category_ske';
import { addKeyToImage, setStorage } from '../utils/utils';
import { useRouter } from 'next/router';
import { Skeleton } from 'antd';
import Item from '../components/ProductList/ProductItem';
import { IOrderType } from '../@types/IProductList';

import { STORAGE_KEYS } from '../utils/config';
import parse from 'html-react-parser';
import HeadComponent from '../components/layout/Head';
import AppContext from '../store/AppContext';
// import SilderComponent from '../components/silder';
const DynamicSilderComponent = dynamic(() => import('../components/silder'));
//import PopupOrder from '../components/PopupOrder';
const DynamicPopupOrder = dynamic(() => import('../components/PopupOrder'));
//import PopupSignup from '../components/PopupSignup';
const DynamicPopupSignup = dynamic(() => import('../components/PopupSignup'));
// import SpecialProducts from '../components/SpecialProducts';
const DynamicSpecialProducts = dynamic(() => import('../components/SpecialProducts'));
export const settings = {
  dots: false,
  infinite: false,
  speed: 800,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const popUpsettings = {
  dots: false,
  infinite: false,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const specialProductsettings = {
  dots: false,
  infinite: false,
  speed: 800,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};


export default function Home() {
  const { branchs, windowWidth } = useContext(AppContext);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [origin, setOrigin] = useState("");
  const [banners, setBanners] = useState<Array<IBanner>>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [productGroups, setProductGroups]: any = useState({
    limit: 9,
    page: 1,
    totalElements: 0,
    data: []
  });
  const [additional, setAdditional] = useState({
    id: "",
    name: "",
    groupName: ""
  })

  function onCheckAlreadySignUp() {


    let name = localStorage.getItem(STORAGE_KEYS.name);
    let email = localStorage.getItem(STORAGE_KEYS.email);
    let phone = localStorage.getItem(STORAGE_KEYS.phone);
    if (!name || !phone) {

      setOpenSignUp(true);
      return false
    } else {

    }
    return true
  }

  const [specialProducts, setSpecialProducts] = useState([]);
  const [productList, setProductList]: any = useState([]);

  const [featuredProducts, setFeaturedProducts] = useState([]);
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  const [openSignUp, setOpenSignUp] = useState(false);
  const bannerLoad = async () => {
    let response: any = await getBanner();
    if (response) {
      setBanners(response);
    }
  }

  function showMoreProductParent() {
    productParentsLoad(productGroups.limit += 9);
  }

  const [isOpen, setIsOpen] = useState<{ isOpen: boolean, orderType: number, is_purchase: boolean | any, id: number | any, isOthers: boolean, is_product: boolean }>({
    isOpen: false,
    orderType: 0,
    is_purchase: null,
    id: null,
    isOthers: false,
    is_product: true
  });

  const productParentsLoad = async (limit?: number) => {
    limit = limit ?? productGroups.limit;
    let response: any = await getProductParent("?limit=" + productGroups.limit + "&page=" + productGroups.page);
    if (response) {
      setProductGroups({
        ...productGroups,
        limit: limit,
        totalElements: response.totalElements,
        data: response.results
      });
    }
  }

  const getSpecialProducts = async () => {
    let response: any = await getSpecialProduct();
    if (response) {
      setSpecialProducts(response);
    }
  }

  const featuredProductsLoad = async () => {
    let response: any = await getFeaturedProduct();
    if (response) {
      setFeaturedProducts(response);
    }
  }

  const getProductList = async () => {
    let response: any = await getProductMain();

    if (response) {
      setProductList(response);
    }
  }

  function openSellPopup(id: any, is_product: boolean, isOthers: boolean) {
    setIsOpen({
      ...isOpen,
      isOpen: true,
      orderType: IOrderType.sell,
      is_purchase: true,
      isOthers: isOthers,
      id: id,
      is_product: is_product
    })
  }

  function openRentPopup(id: any, is_product: boolean, isOthers: boolean) {
    setIsOpen({
      ...isOpen,
      isOpen: true,
      orderType: IOrderType.rent,
      is_purchase: false,
      isOthers: isOthers,
      id: id,
      is_product: is_product
    })
  }

  function openOrderTrialPopup(id: any, is_product: boolean, isOthers: boolean) {
    setIsOpen({
      ...isOpen,
      isOpen: true,
      orderType: IOrderType.orderCalendar,
      isOthers: isOthers,
      id: id,
      is_product: is_product
    })
  }

  function closePopup() {
    setIsOpen({
      ...isOpen,
      isOpen: false,
      is_purchase: null,
    })
  }


  async function firstLoad() {
    setIsMobile(window.innerWidth < 700);
    Promise.all([bannerLoad(), productParentsLoad(), featuredProductsLoad(), getProductList(), getSpecialProducts()]).then((values) => {
    });
    // bannerLoad(), productParentsLoad(), featuredProductsLoad(), getProductList(), getSpecialProducts()
  }

  function setAdditionalId(id: any, name: any) {
    setAdditional({
      ...additional,
      id: id,
      name: name,
    })
  }

  useEffect(() => {
    setIsLoading(true);
    firstLoad();
    setIsLoading(false);
    //animation
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate_show")
        } else {
          entry.target.classList.remove("animate_show")
        }
      })
    })

    const hiddenElements = document.querySelectorAll(".animate_hidden");
    hiddenElements.forEach((el) => observer.observe(el));
    setOrigin(window?.location?.origin);
  }, []);

  useEffect(() => {
    let description1 = document.getElementById("description1");
    if (description1) {
      description1.innerHTML = productList[0]?.description ?? " ";
    }

    let description2 = document.getElementById("description2");
    if (description2) {
      description2.innerHTML = productList[1]?.description ?? " ";
    }
  }, [productList])


  useEffect(() => {

  }, [])

  return (
    <>
      <HeadComponent isMain={true} />
      <LayoutComponent className='' isLoading={isLoading}>
        {
          !isLoading ? <section className='homepage-component m-auto'>
          {/* Banner */}
          <div className='banner'>
            {banners ? < DynamicSilderComponent windowWidth={windowWidth} banners={banners} /> : <Skeleton.Image active className="w-full h-[12rem] md:h-[40rem] relative" />}
          </div>
          {/* Nhóm sản phẩm */}
          <div className='product-group parent-group'>
            <div className='wrap-title hidden md:flex'>
              <div className='divider'></div>
              <h1 className='title'>DANH MỤC SẢN PHẨM</h1>
              <div className='divider'></div>
            </div>
            {productGroups?.data && productGroups?.data.length > 0 ? <div
              className={`
            wrap-product-groups
            container 
            mx-auto 
            px-2 
            lg:px-6 
            mb-12`}>
              {productGroups.data?.map((x: any, index: any) => {
                return (
                  <Link
                    title={x?.name}
                    href={{ pathname: '/[...group]', query: { group: [x.code] } }}
                    key={x?.id}
                    className='h-56 lg:h-60 xl:h-80 wrap-product-card cursor-pointer'>
                    <div className='w-full justify-center h-full product-card flex flex-col p-2 gap-2 md:gap-4'>
                      <div className='rounded-full aspect-square w-full md:h-36 md:w-36 lg:h-40 lg:w-40 xl:w-52 xl:h-52 product mx-auto flex justify-center items-center background-product relative overflow-hidden relative group'>
                        <Image
                          loading="lazy"
                          src={x.img_url ? (environment.baseImageUrl + addKeyToImage(x.img_url, windowWidth, false, false, true)) : "/asset/image/No_Image_Available.webp"}
                          alt={x?.alt ?? x?.name}
                          title={x?.alt ?? x?.name}
                          className='w-full h-full rounded-full '
                          layout='fill'
                          objectFit='cover'
                        />
                        <div className='group-hover:flex absolute hidden opacity-75 bg-white w-full h-full items-center justify-center'>
                          <p className='break_2 text-[#262626] text-base xl:text-lg'>{x?.total_product ?? 0} sản phẩm</p>
                        </div>
                      </div>
                      <p className='name-product text-xs md:text-base break_2 max-h-12 h-[32px] md:h-[48px]'>{x.name}</p>
                    </div>
                  </Link>
                )
              })
              }
              {
                productGroups.totalElements - productGroups.limit > 0 && <div id='more_product' key={"more"} onClick={showMoreProductParent} className='h-60 lg:h-60 xl:h-80 wrap-product-card cursor-pointer'>
                  <div className='w-full justify-center h-full product-card flex flex-col gap-1 p-2 md:gap-4'>
                    <div className="h-40 w-full lg:h-40 lg:w-40 xl:w-52 xl:h-52 product mx-auto flex justify-center items-center background-product relative overflow-hidden">
                      <Image
                        loading="lazy"
                        className='m-auto'
                        src={"/asset/icon/ico_show_more.svg"}
                        alt={"more"}
                        width={40}
                        height={40}
                      />
                    </div>
                    <p className='name-product text-xs md:text-base'>Xem thêm danh mục</p>
                  </div>
                </div>
              }
            </div> : <ProductCategorySke />}

          </div>

          {
            productList?.map((item: any, index: number) => {
              return <section key={item.id} title={item?.name}
                className={`
                pb-10
              container
              mx-auto
              px-2
              lg:px-6
              gap-8 
              flex 
              ${index % 2 === 0 ? "flex-col md:flex-row" : "flex-col md:flex-row-reverse"}
              items-center 
              overflow-hidden 
              relative 
              
              w-full`}>
                <div className='flex w-full md:w-1/2 h-[684px] justify-center relative'>
                  {item?.image && windowWidth !== -1 && <Image
                    className='relative'
                    src={environment.baseImageUrl + addKeyToImage(item?.image?.file_url, windowWidth, true, false, false, true)}
                    alt="g"
                    layout='fill'
                    objectFit='cover'
                  />}
                </div>
                <div className='flex flex-col py-16 gap-8 w-full md:w-1/2 max-h-[684px] overflow-hidden'>
                  <h2 className='text-xl lg:text-2xl text-[#434343] text-center font-bold'>{item?.name}</h2>
                  <p className='text-center text-sm md:text-base text-[#434343] max-h-[684px] overflow-hidden'>{parse(item?.content ?? "")}</p>
                  <div className='flex justify-center'>
                    <button onClick={() => {
                      if (item) {
                        setStorage(item?.id, item?.name);
                        router.push(item?.url?.replace(/^\/+/, '') ?? "")
                      }
                    }} className='button-album mt-3'>
                      Xem Album
                    </button>
                  </div>

                </div>
              </section>;
            })
          }

          {/* Danh sách nổi bật */}
          <section className='flex flex-col gap-8 pb-32 mx-2 lg:mx-8 overflow-hidden'>
            <div className='wrap-title feature_product_title'>
              <div className='divider'></div>
              <p className='title title--box text-base md:text-[2rem]'>SẢN PHẨM NỔI BẬT</p>
              <div className='divider'></div>
            </div>
            <div className=' w-full relative'>
              <Slider {...specialProductsettings}>
                {
                  specialProducts?.map((item: any) => {
                    return <div key={item.id} className=' w-full px-2 '>
                      <Item
                        setAdditionalId={setAdditionalId}
                        setOpenSignUp={setOpenSignUp}
                        setIdOrderTrialPopup={() => { }}
                        openSellPopup={openSellPopup}
                        openRentPopup={openRentPopup}
                        openOrderTrialPopup={openOrderTrialPopup}
                        item={item}
                        key={item.id} />
                    </div>
                  })
                }
              </Slider>
            </div>
          </section>

          {/* Sản phẩm nổi bật */}
          <section className='product-group mb-12 lg:pt-16 pt-4'>
            <div className='wrap-title feature_product_title'>
              <div className='divider'></div>
              <p className='title title--box text-base md:text-[2rem]'>SẢN PHẨM BÁN CHẠY</p>
              <div className='divider'></div>
            </div>
            <DynamicSpecialProducts items={featuredProducts} windowWidth={windowWidth} />
          </section>
          {/* Comment customer */}
          <section className='wrap-comment-customer'>
            <Image
              loading='lazy'
              className='w-full'
              src="/asset/image/background.webp"
              alt="Bình luận khách hàng"
              layout='fill'
              objectFit='cover'
            />
            <div className='background-content'>
              <div className='wrap-comment'>
                <span className='title-comment'>Cảm nhận của khách hàng</span>
                <div className='box-comment p-8 flex flex-col gap-8'>
                  <div className='flex gap-4'>
                    <div className='w-28 h-28 relative wrap-image-customer'>
                      <Image
                        src="/asset/image/customer_1.webp"
                        alt="Bình luận khách hàng"
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                    <div className='flex flex-col gap-2 justify-center'>
                      <span className='name-customer'>Chú Tuấn</span>
                      <span className='address'>(Quận Tân Phú)</span>
                    </div>
                  </div>
                  <div className='content-comment leading-6'>
                    <p>Tôi đã thuê 1 bộ comple tại đây để dự lễ cưới của con gái. Tôi rất hàng lòng vì đã tìm được bộ đồ mặc vừa và đẹp. Chúc cửa tiệm ngày càng thành công.</p>
                  </div>
                </div>
                <div className='navigation-comment'>
                  <div className='button-navigation'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src="/asset/icon/ico_arrow_left.svg"
                        alt="back"
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                  </div>
                  <div className='button-navigation'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src="/asset/icon/ico_arrow_right.svg"
                        alt="back"
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section> : <Image
                priority={true}
                className='m-auto'
                src={"/asset/image/loading.svg"}
                alt="product_tab_item"
                width={128}
                height={128}
            /> 
        }
        
      </LayoutComponent>
      <Image
        src={"/asset/icon/ico_gotop.svg"}
        alt="Go Top"
        onClick={topFunction}
        className='gotop'
        width={48}
        height={48}
      />

      {isOpen.isOpen == true && <DynamicPopupOrder
        isOthers={isOpen.isOthers}
        is_purchase={isOpen.is_purchase}
        isOpen={isOpen.isOpen}
        setIsOpen={closePopup}
        id={isOpen.id}
        orderDate={{
          takeDate: null,
          returnDate: null
        }}
        orderType={isOpen.orderType}
        branchs={branchs}
        detailData={null} is_product={isOpen.is_product} />}
      {openSignUp == true && <DynamicPopupSignup
        is_product={isOpen.is_product}
        id={isOpen.id}
        setOpen={setOpenSignUp}
        additionalFunc={() => {
          if (onCheckAlreadySignUp()) {
            if (isMobile) {
              //setPhaseMobile(IOrderType.orderCalendar);
            } else {
              openOrderTrialPopup(isOpen.id, false, isOpen.is_product);
            }
          }
        }} />}
    </>
  )
}
